import React from "react";
// import { Hero } from "../components/Hero";
import { CarouselPage } from "../components/Carousel";
import { features, slideData } from "../data/data";
// import { slide1, slide3, slide5 } from "../images";

export const Home = () => {

  return (
    <div className="bg-white">
      <div className="z-10">
        <CarouselPage
          slideInterval={6000}
          indicators={false}
          leftControl="&nbsp;"
          rightControl="&nbsp;"
          slideData={slideData}
        />
      </div>

      {/* <div className="absolute inset-0 z-30"><Hero /></div> */}

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Services
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We provide the perfect service for you.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-20">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-[80px] w-[70px] items-center justify-center rounded-lg bg-white">
                      <img
                        src={feature.image}
                        alt={`${feature.name} Icon`}
                        className="h-[80px] w-[70px] object-contain"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
