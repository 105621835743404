import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { About } from "./pages/About";
import { Product } from "./pages/Product";
import { Shop } from "./pages/Shop";
import { NoPage } from "./pages/NoPage";
import { ProductDetails } from "./components/ProductDetails";
import { ScrollToTop } from "./components/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="product" element={<Product />}>
            <Route path=":id" element={<ProductDetails />} />
          </Route>
          <Route path="shop" element={<Shop />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
