import React from "react";
import { Carousel } from "flowbite-react";
import { Link } from "react-router-dom";

export const CarouselPage = (props) => {
  return (
    // <div className="h-screen sm:h-64 xl:h-80 2xl:h-96">
    <div className="h-screen">
      <Carousel
        slideInterval={props.slideInterval}
        indicators={props.indicators}
        leftControl={props.leftControl}
        rightControl={props.rightControl}
        className="h-full"
      >
        {props.slideData &&
          props.slideData.map((slide, index) => (
            <div key={index} className="relative h-full w-full">
              <img
                src={slide.image}
                className="object-cover object-center w-full h-full"
                alt={slide.alt}
              />
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-25 px-6 pt-36 lg:px-8">
                <h1 className="text-4xl text-center font-bold tracking-tight text-white sm:text-6xl">
                  {slide.title}
                </h1>
                <p className="mt-6 text-lg text-center text-white">
                  {slide.text}
                </p>
                <div className="mt-10 md:mt-24 sm:mt-32 lg:mt-20 flex gap-x-6">
                  <Link
                    to="product"
                    className="transition duration-700 ease-in-out bg-green-400 px-8 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-transparent hover:border-green-400 border border-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Get started
                  </Link>
                  <Link
                    to="contact"
                    className="bg-transparent text-lg font-semibold leading-6 text-green-400 border border-green-400 px-8 py-2.5"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};
