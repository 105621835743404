import React from "react";
import { PageHeader } from "../components/PageHeader";
import { SesameFlower } from "../images";

export const About = () => {
  return (
    <div>
      <div>
        <PageHeader pageTitle="ABOUT US" />
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            aria-hidden="true"
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p> */}
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  About Us
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  At Yebint Nigeria Limited (YNL), we are dedicated to
                  delivering the finest agricultural products from our local
                  farms to global markets. Specializing in the export of premium
                  Sesame seeds, Ginger, and Hibiscus, we take pride in ensuring
                  that every product meets the highest standards of quality and
                  sustainability.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
            alt=""
            src={SesameFlower}
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <p>
                  Our mission is to bridge the gap between local producers and
                  international buyers, offering natural and organic products
                  that contribute to healthier lifestyles and sustainable trade
                  practices. By maintaining strong relationships with our
                  network of farmers and adhering to rigorous quality control,
                  we guarantee that our clients receive only the best.
                </p>
               
                <p className="mt-8">
                  With years of experience in the export industry, Yebint
                  Nigeria Limited (YNL) is committed to promoting the rich
                  agricultural heritage of our region while fostering long-term
                  partnerships around the world. Whether you are in need of bulk
                  shipments or customized orders, we have the expertise and
                  flexibility to meet your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
