import ginger from "../images/bg-images/ginger.jpeg";
import sesame from "../images/bg-images/sesame.avif";
import hibiscus from "../images/bg-images/hibiscus.jpg";
import {
  GingerFlower,
  GingerCup,
  GingerPlant,
  HibiscusFlower,
  HisbiscusCup,
  HisbiscusPlant,
  SesameFlower,
  SesameCup,
  SesamePlant,
  coreImage1,
  coreImage2,
  coreImage3,
  coreImage4,
  slide1,
  slide3,
  slide5,
} from "../images";

export const navigation = [
  { name: "Home", path: "/" },
  { name: "About", path: "about" },
  // { name: "Shop", path: "shop" },
  { name: "Product", path: "product" },
  { name: "Contact", path: "contact" },
];

export const slideData = [
  {
    image: slide1,
    alt: "Sustainable Farming Practices",
    title: `Sustainable Farming Practices`,
    text: `Empowering Farmers with Sustainable Solutions
Discover our innovative farming techniques that promote environmental health while maximizing yield. Join us in cultivating a greener future.`,
  },
  {
    image: slide3,
    alt: "Quality Crop Production",
    title: "Quality Crop Production",
    text: `Quality You Can Trust
Our commitment to quality ensures that every crop meets the highest standards. Experience the taste of freshness and reliability with our premium produce.`,
  },
  {
    image: slide5,
    alt: "Community and Partnership",
    title: `Community and Partnership`,
    text: `Building Stronger Communities Together
We believe in collaboration. Partnering with local farmers and communities to create a sustainable agricultural ecosystem. Together, we can grow more than just crops.`,
  },
];

export const products = [
  {
    id: 1,
    name: "Ginger",
    href: "#",
    imageSrc: ginger,
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
    images: [
      {
        src: ginger,
        alt: "Two each of gray, white, and black shirts laying flat.",
      },
      {
        src: GingerFlower,
        alt: "Model wearing plain black basic tee.",
      },
      {
        src: GingerCup,
        alt: "Model wearing plain gray basic tee.",
      },
      {
        src: GingerPlant,
        alt: "Model wearing plain white basic tee.",
      },
    ],
    description:
      "Ginger, scientifically known as Zingiber officinale, is a flowering plant whose rhizome, commonly known as ginger root, is widely used as a spice and in traditional medicine. Here's some overview:",
    highlights: [
      "Nausea and Vomiting: Particularly effective against morning sickness during pregnancy and nausea from chemotherapy.",
      "Weight Loss: Some studies suggest ginger may play a role in weight management by enhancing fat burning and reducing appetite.",
      "Heart Health: May lower blood sugar levels, improve heart disease risk factors, and help reduce cholesterol levels.",
      "Menstrual Pain: Research suggests that ginger may be as effective as some pain relief medications for menstrual pain.",
    ],
    details: ` The ginger plant is a perennial herb that grows about 3-4 feet tall.
  It has narrow, lance-shaped leaves, yellow-green flowers, and thick, branching aromatic rhizomes.
  The rhizome is the part commonly used in cooking and medicine.`,
  },
  {
    id: 2,
    name: "Sesame",
    href: "#",
    imageSrc: sesame,
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
    images: [
      {
        src: sesame,
        alt: "Two each of gray, white, and black shirts laying flat.",
      },
      {
        src: SesameFlower,
        alt: "Model wearing plain black basic tee.",
      },
      {
        src: SesameCup,
        alt: "Model wearing plain gray basic tee.",
      },
      {
        src: SesamePlant,
        alt: "Model wearing plain white basic tee.",
      },
    ],
    description:
      "Sesame seeds, derived from the Sesamum indicum plant, are one of the oldest cultivated oilseed crops known to humanity. They are widely valued for their rich flavor, high oil content, and various health benefits. Here's a comprehensive overview:",
    highlights: [
      "Heart Health: The lignans (sesamin and sesamolin) and phytosterols in sesame seeds can help lower cholesterol levels and improve cardiovascular health.",
      "Bone Health: High calcium and phosphorus content support bone density and health.",
      "Antioxidant Properties: Sesame seeds are rich in antioxidants, which help reduce oxidative stress and may lower the risk of chronic diseases.",
      "Anti-inflammatory: The compounds in sesame seeds, including sesamin, have anti-inflammatory properties that can be beneficial for conditions like arthritis.",
      "Blood Sugar Control: Some studies suggest that sesame seeds may help regulate blood sugar levels, making them beneficial for people with diabetes.",
    ],
    details: `Sesame seeds are a versatile and nutritious food with a rich history and cultural significance, playing a crucial role in both traditional and modern culinary, medicinal, and industrial applications.`,
  },
  {
    id: 3,
    name: "Hibiscus",
    href: "#",
    imageSrc: hibiscus,
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
    images: [
      {
        src: hibiscus,
        alt: "Two each of gray, white, and black shirts laying flat.",
      },
      {
        src: HibiscusFlower,
        alt: "Model wearing plain black basic tee.",
      },
      {
        src: HisbiscusCup,
        alt: "Model wearing plain gray basic tee.",
      },
      {
        src: HisbiscusPlant,
        alt: "Model wearing plain white basic tee.",
      },
    ],
    description:
      "Roselle (Hibiscus sabdariffa), also known as rosella or red sorrel, is a tropical plant widely appreciated for its edible calyxes, which are used in beverages, foods, and traditional medicine. Here's an in-depth look at the Roselle plant",
    highlights: [
      "Antioxidant Properties: Roselle is rich in antioxidants that help combat oxidative stress, which can lead to chronic diseases.",
      "Blood Pressure Regulation: Studies have shown that hibiscus tea can help lower blood pressure in people with mild hypertension.",
      "Digestive Health: The plant's high fiber content promotes healthy digestion, and the calyxes have mild diuretic properties that may help with bloating.",
      "Immune Support: The high Vitamin C content supports the immune system and may help reduce the duration of colds and flu.",
      "Weight Management: Some studies suggest that hibiscus extract may help with weight loss by reducing fat absorption and improving lipid metabolism.",
      "Liver Health: Research indicates that hibiscus extract may have protective effects on the liver, potentially aiding in liver function.",
    ],
    details:
      "Roselle is a versatile and valuable plant with a rich history and a wide range of applications, from traditional medicine to modern cuisine and industry. Its potential health benefits and unique flavor make it a popular ingredient in many cultures around the world.",
  },
  // {
  //   id: 1,
  //   name: "Basic Tee",
  //   href: "#",
  //   imageSrc:
  //     "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
  //   imageAlt: "Front of men's Basic Tee in black.",
  //   price: "$35",
  //   color: "Black",
  // },

  // More products...
];

export const features = [
  {
    name: "Agricultural Inputs Supply",
    description:
      "We provide a comprehensive range of agricultural inputs, including seeds, fertilizers, and pesticides. Our products are sourced from reputable manufacturers to ensure high performance and reliability in various farming conditions.",
    image: coreImage1,
  },
  {
    name: "Crop Protection",
    description:
      "Our crop protection services include the supply of effective pesticides and herbicides that safeguard crops from pests and diseases. We also offer expert advice on integrated pest management to ensure sustainable farming practices..",
    image: coreImage2,
  },
  {
    name: "Farm Management Solutions",
    description:
      "Our farm management services include advisory on best practices for crop production, irrigation, and pest control. We provide tailored solutions to enhance operational efficiency and productivity.",
    image: coreImage3,
  },
  {
    name: "Soil Health Management",
    description:
      "We offer soil testing and analysis services to help farmers understand soil health and nutrient requirements. Our recommendations on soil amendments and fertilizers aim to optimize crop yields and promote soil fertility.",
    image: coreImage4,
  },
];

// const product = {
//   name: "Basic Tee 6-Pack",
//   price: "$192",
//   href: "#",
//   breadcrumbs: [
//     { id: 1, name: "Men", href: "#" },
//     { id: 2, name: "Clothing", href: "#" },
//   ],
//   images: [
//     {
//       src: "https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg",
//       alt: "Two each of gray, white, and black shirts laying flat.",
//     },
//     {
//       src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg",
//       alt: "Model wearing plain black basic tee.",
//     },
//     {
//       src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg",
//       alt: "Model wearing plain gray basic tee.",
//     },
//     {
//       src: "https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg",
//       alt: "Model wearing plain white basic tee.",
//     },
//   ],
//   colors: [
//     { name: "White", class: "bg-white", selectedClass: "ring-gray-400" },
//     { name: "Gray", class: "bg-gray-200", selectedClass: "ring-gray-400" },
//     { name: "Black", class: "bg-gray-900", selectedClass: "ring-gray-900" },
//   ],
//   sizes: [
//     { name: "XXS", inStock: false },
//     { name: "XS", inStock: true },
//     { name: "S", inStock: true },
//     { name: "M", inStock: true },
//     { name: "L", inStock: true },
//     { name: "XL", inStock: true },
//     { name: "2XL", inStock: true },
//     { name: "3XL", inStock: true },
//   ],
//   description:
//     'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
//   highlights: [
//     "Hand cut and sewn locally",
//     "Dyed with our proprietary colors",
//     "Pre-washed & pre-shrunk",
//     "Ultra-soft 100% cotton",
//   ],
//   details:
//     'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
// };
