import React, { useRef } from "react";
import { PageHeader } from "../components/PageHeader";
import { contactImg } from "../images";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const form = useRef();
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, form.current, {
        publicKey: PUBLIC_KEY,
      })
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          alert("Message Sent!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Something went wrong. Please try again later");
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <PageHeader pageTitle="CONTACT" />
      <div className="mt-20 lg:flex lg:gap-x-40 p-20">
        <div className="overflow-hidden shadow-2xl">
          <img src={contactImg} alt="" className="h-80" />
        </div>
        <div>
          <div className="mb-10">
            <h1 className="lg:text-2xl font-bold sm:text-sm sm:leading-6">
              CONTACT US
            </h1>
            <p className="mt-2 lg:text-base leading-7 text-gray-600 sm:text-sm sm:leading-6">
              We are improving our services to serve you better.
            </p>
          </div>
          <div>
            <p className="text-base leading-7 text-gray-600">
              <span className="font-medium">Address:</span> No 19 Otukpo street
              off Gimbiya street garki area 11, Abuja
            </p>
            <p className="text-base leading-7 text-gray-600">
              <span className="font-medium">Phone:</span> +234 803 872 1112
            </p>
            <p className="text-base leading-7 text-gray-600">
              <span className="font-medium">Email: </span>
              yebintnigerialimited@gmail.com
            </p>
            <p className="text-base leading-7 text-gray-600">
              <span className="font-medium">Open hours:</span> Mon - Sun: 8 AM
              to 9 PM
            </p>
            <p className="text-base leading-7 text-gray-600">
              <span className="font-medium">Happy hours:</span> Sat: 2 PM to 4
              PM
            </p>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:gap-x-20 p-20 items-center">
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          >
          </div>
          <div className="mx-auto max-w-2xl text-left">
            <h2 className="lg:text-2xl font-bold sm:text-sm sm:leading-6">
              GET IN TOUCH
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Send us a message, we will call back later
            </p>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            // action="#"
            // method="POST"
            className="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    id="first-name"
                    name="first_name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    id="last-name"
                    name="last_name"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Subject
                </label>
                <div className="mt-2.5">
                  <input
                    id="company"
                    name="subject"
                    type="text"
                    autoComplete="organization"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Phone number
                </label>
                <div className="mt-2.5">
                  {/* <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    <option>US</option>
                    <option>CA</option>
                    <option>EU</option>
                  </select>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                  />
                </div> */}
                  <input
                    id="phone-number"
                    name="phone_number"
                    type="tel"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Let's talk
              </button>
            </div>
          </form>
        </div>
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 overflow-hidden">
          <iframe
            title="location_frame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.249844885645!2d7.502632574096168!3d9.040959191020843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0bec82fdaca5%3A0xf80c43f8fc0b6db4!2s19%20Otukpo%20Street%2C%20Garki%2C%20Abuja%20900103%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1726413298153!5m2!1sen!2sng"
            width="600"
            height="450"
            className="border-0 shadow-xl"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <iframe
            title="location_frame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31519.408491310467!2d7.453907798454511!3d9.070500723502732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0afd946acf11%3A0x641c035f13845f8b!2sWuse%2C%20Abuja%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1725447044230!5m2!1sen!2sng"
            width="600"
            height="450"
            className="shadow-2xl"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
      </div>
    </div>
  );
};
