import React, { useState } from "react";
import { products } from "../data/data";
import { PageHeader } from "../components/PageHeader";
import { ProductDetails } from "../components/ProductDetails";
import { useNavigate } from "react-router-dom";

export const Product = () => {
  const [open, setOpen] = useState(false);
  const [productData, setProductData] = useState({});

  const navigate = useNavigate();

  const handleClick = (data) => {
    setOpen(true);
    setProductData(data);
    navigate(`../product/${data.id}`);
  };

  return (
    <div className="bg-white">
      <PageHeader pageTitle="PRODUCT" />
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Our Products
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Explore our product range below. Click on any image to view detailed
          information about each item.
        </p>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} className="group relative">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  alt={product.imageAlt}
                  src={product.imageSrc}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <button
                onClick={() => handleClick(product)}
                className="absolute invisible group-hover:visible bg-gray-100 inset-x-3 rounded-lg px-10 py-2 z-10 opacity-75 bottom-14"
              >
                Quick view
              </button>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    {/* <a href={product.href}> */}
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                    {/* </a> */}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                </div>

                {/* <p className="text-sm font-medium text-gray-900">
                  {product.price}
                </p> */}
              </div>
            </div>
          ))}
        </div>
        <div>
          {productData && (
            <ProductDetails
              open={open}
              setOpen={setOpen}
              productData={productData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
