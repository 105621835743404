import React from "react";
import pageImage from "../images/bg-images/24.jpg";

export const PageHeader = ({ pageTitle }) => {
  return (
    <div className="lg:h-96 sm:h-fit overflow-hidden relative">
      <div className="">
        <img src={pageImage} alt="" className="object-contain" />
        <div className="absolute inset-0 bg-black bg-opacity-50 px-6 pt-20 lg:px-8 flex items-center justify-center">
          <h1 className="text-white text-base sm:text-lg md:text-2xl lg:text-3xl xl:text-5xl">{pageTitle}</h1>
        </div>
      </div>
    </div>
  );
};
