import React, { useEffect } from "react";

import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { navigation } from "../data/data";
import { yebint } from "../images";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navColor, setNavColor] = useState("bg-inherit");

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setNavColor("bg-black") : setNavColor("bg-inherit");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  //background color: [#707070]
  return (
    // <div className="bg-white">

    <header className="absolute inset-x-0 top-0 z-40 relative">
      <nav
        aria-label="Global"
        className={`flex focus:bg-black items-center justify-between p-9 lg:px-8 fixed top-0 left-0 right-0 ${navColor}`}
      >
        {/* <div className="flex lg:flex-1 overflow-hidden"> */}
        <Link to="/" className="-m-1.5 p-1.5">
          <div className="flex lg:flex-1 overflow-hidden items-center">
            <img
              alt=""
              src={yebint}
              className="w-16 h-16 rounded-full border-green-400 border-2"
            />
            <span className="text-2xl font-bold ml-3">
              <span className="text-green-400">Yebint</span>{" "}
              <span className="text-white">Nigeria</span>{" "}
              <span className="text-green-400">Limited</span>
            </span>
          </div>
        </Link>
        {/* </div> */}
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className="text-lg font-medium leading-6 text-white hover:text-green-400 transition duration-700 ease-in-out"
            >
              {item.name}
            </Link>
          ))}
        </div>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div> */}
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        {/* <div className="fixed inset-0 z-50" /> */}
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="-m-1.5 p-1.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="">
                <span className="text-green-400">Yebint</span>{" "}
                <span className="text-black">Nigeria</span>{" "}
                <span className="text-green-400">Limited</span>
              </span>
              {/* <img
                    alt=""
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  /> */}
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              {/* <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
